import { ModelType } from '@/app/base/schemas/BaseSchema';
import PlanningItemCollection from '@/app/planning/collections/PlanningItemCollection';
import PlanningItem from '@/app/planning/models/PlanningItem';
import User from '@/app/auth/models/User';
import CalendarEventCollection from '@/app/planning/collections/CalendarEventCollection';
import CalendarEvent from '@/app/planning/models/CalendarEvent';
import TaskCollection from '@/app/task/collections/TaskCollection';
import Task from '@/app/task/models/Task';
import WorksitePhaseCollection from '@/app/worksite/collections/WorksitePhaseCollection';
import WorksitePhase from '@/app/worksite/models/WorksitePhase';
import moment from '@/utils/moment';
import { QueryParameter } from '@/utils/reactiveQueryParameter';
import Worksite from '@/app/worksite/models/Worksite';
import { CollectionOnUpdatePayload } from '@/app/base/collections/BaseCollection';
import { CalendarEventTypeValue } from '@/app/planning/enums/CalendarEventType';

export default function useGetPlanningItems(filtersItems?: Array<QueryParameter>, worksite?: Worksite, onCollectionLoaded?: (collection: PlanningItemCollection) => PlanningItemCollection) {
    const { loading, collection, load, filters } = useCollectionLoader<PlanningItem, PlanningItemCollection>(
        ModelType.PLANNING_ITEMS,
        filtersItems ?? [
            {
                key: 'users',
            },
            {
                key: 'worksites',
            },
            {
                key: 'start',
                defaultValue: moment().startOf('isoWeek').toDateString(),
                transform: (value: string) => moment(value),
            },
            {
                key: 'end',
                defaultValue: moment().endOf('isoWeek').toDateString(),
                transform: (value: string) => moment(value),
            },
            {
                key: 'calendarEventTypes',
                defaultValue: CalendarEventTypeValue.join(','),
            },
        ],
        () => {
            const params = { filter: {} };
            if (filters.getRaw('start') && filters.getRaw('end')) {
                params.filter.dateBetween = `${filters.getRaw('start')},${filters.getRaw('end')}`;
            }

            if (filters.hasItem('worksites') && filters.getRaw('worksites')) {
                params.filter.worksite = {
                    id: filters.getRaw('worksites').split(','),
                };
            }

            if (filters.hasItem('users') && filters.getRaw('users')) {
                params.filter.users = {
                    id: filters.getRaw('users').split(','),
                };
            }

            if (filters.hasItem('calendarEventTypes') && filters.get('calendarEventTypes')) {
                params.filter.calendarEventTypes = filters.get('calendarEventTypes').split(',');
            }

            if (worksite) {
                params.filter.worksite = {
                    id: [worksite.getId()],
                };
            }

            return params;
        },
        (collection: PlanningItemCollection) => {
            tasks.value = collection.tasks();
            worksitePhases.value = collection.worksitePhases();
            calendarEvents.value = collection.calendarEvents();

            if (onCollectionLoaded) {
                collection = onCollectionLoaded(collection);
            }

            return collection;
        }
    );

    const tasks = reactiveCollection<Task, TaskCollection>(null);
    const worksitePhases = reactiveCollection<WorksitePhase, WorksitePhaseCollection>(null);
    const calendarEvents = reactiveCollection<CalendarEvent, CalendarEventCollection>(null);
    const days = computed(() => moment.range(filters.get('start'), filters.get('end')).by('days'));

    const getWorksitePhasesForDay = (day: moment.Moment): WorksitePhase[] => {
        return worksitePhases.value?.whereDate(day) ?? [];
    };
    const getTasksForDay = (day: moment.Moment): Task[] => {
        return tasks.value?.whereDate(day) ?? [];
    };
    const getCalendarEventsForUserAndDay = (user: User, day: moment.Moment): CalendarEvent[] => {
        const array = calendarEvents.value?.whereHasUserAndDate(user, day) ?? [];

        return array.sort((a, b) => {
            if (a.startsAt?.isBefore(b.startsAt)) {
                return -1;
            }
            if (a.startsAt?.isAfter(b.startsAt)) {
                return 1;
            }
            return 0;
        });
    };

    onBeforeUnmount(() => {
        tasks.value?.destroy();
        worksitePhases.value?.destroy();
        calendarEvents.value?.destroy();
    });

    return {
        loading,
        collection,
        load,
        filters,
        tasks,
        worksitePhases,
        calendarEvents,
        days,
        getWorksitePhasesForDay,
        getTasksForDay,
        getCalendarEventsForUserAndDay,
    };
}
